<template>
  <div class="card-holder__body-row df-fc">
    <ul class="validation-errors" v-if="errors.length > 0">
      <li :key="e" v-for="e in errors">{{ e }}</li>
    </ul>
    <label for="" class="card-holder-label">
      {{ name }}
      <span v-if="required" class="c-c-danger">*</span>
    </label>
    <textarea v-if="textarea" class="c-input c-textarea c-textarea-n-resize"
              :class="{'unvalidate': validationFailed}"
              @change="change"
              :disabled="disabled"
              :value="value"></textarea>
    <NumberInput v-if="number"
                 :input-class="{'c-input': true, 'unvalidate': validationFailed}"
                 @change="change"
                 :disabled="disabled"
                 :rounded="rounded"
                 :float-format="floatFormat"
                 v-model="valueNumber"
    />
    <select v-if="select" v-model="value"
            @change="change" class="c-input c-select" :disabled="disabled">
      <option :value="i[itemKey]"
              :key="i[itemKey]" v-for="i in items">
        {{ i[itemName] }}
      </option>
    </select>
    <slot name="field" :validationFailed="validationFailed"></slot>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: 'ValidateInput',
  props: {
    textarea: Boolean,
    select: Boolean,
    number: Boolean,
    required: Boolean,
    disabled: Boolean,
    rounded: Boolean,
    items: {
      type: Array,
    },
    itemKey: {
      type: String,
      default: "",
    },
    itemName: {
      type: String,
      default: "",
    },
    floatFormat: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "",
    },
    modelValue: {
      default: "",
    }
  },
  data() {
    return {
      value: "",
      valueNumber: 0,
      validationFailed: false,
      errors: [],
      isSett: false,
    };
  },
  emits: [
    'update:modelValue',
  ],
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        if (this.number) {
          this.valueNumber = newValue
        } else {
          if (this.isSett) {
            this.setValue(newValue)
          } else {
            this.value = newValue
          }
        }
        this.isSett = true
      },
    },
  },
  methods: {
    change(event) {
      if (this.number) {
        this.setValueNumber(this.valueNumber);
      } else {
        this.setValue(event.target.value);
      }
    },
    setValue(newValue) {
      let oldValue = this.value;
      this.value = newValue

      this.validationFailed = false
      this.errors = []
      if (this.required && newValue === "") {
        this.validationFailed = true
        this.errors.push("Поле обязательно для заполнения")
      }

      this.$emit('update:modelValue', newValue, oldValue);
    },
    setValueNumber(newValue) {
      this.validationFailed = false
      this.errors = []
      if (this.required && !newValue) {
        this.validationFailed = true
        this.errors.push("Поле обязательно для заполнения")
      }
      this.$emit('update:modelValue', newValue, newValue);
    }
  }
})
</script>

<style scoped>

</style>