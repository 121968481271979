<script setup async>
import {PurchaseRequestService, UserService} from "@/common/api.service";
import {useRoute, useRouter} from 'vue-router'
import {computed, onMounted, onUnmounted, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import {useToast} from "vue-toastification";
import shared from "@/common/shared";
import moment from "moment/moment";
import QuilMention from "quill-mention";
import ChatPagination from "@/components/partial/ChatPagination.vue";
import {QuillEditor} from "@vueup/vue-quill";
import ValidateInput from "@/components/validate/input.vue";
import ECP from "@/components/partial/ECP.vue";
import {useDropzone} from "vue3-dropzone";

const route = useRoute()
const router = useRouter()
const toast = useToast()
const store = useStore()
const statusHistory = ref([])
const fileHistory = ref([])
const chiefs = ref([])
const writers = ref([])
const readers = ref([])
const uozers = ref([])
const ecps = ref([])
const editorData = ref("")
const chatMessages = ref({})
const chatFiles = ref([])
const currentPage = ref(1)
const signers = ref([])
const editSigners = ref({TYPE: ''})
const docFile = ref()
const toRevisionForm = ref({comment: ''})
const request = ref({
  page: currentPage.value
})
const item = ref({
  EXPENSES: [],
  EXPENSES_FHD: [],
  AVAILABLE_TRANSITIONS: []
})
const editStatus = ref({
  TRANSITION: "",
  COMMENT: "",
  UF_TEXT_LABEL_CONTROL: ""
})
const editUOZ = ref({
  UF_RESPONSIBLE_UOZ_ID: ""
})
const edsInfo = ref({
  issuer: "",
  author: {
    company: "",
    full_name: ""
  },
  thumbprint: ""
})
const showSign = ref(false)
const edsType = ref('')
const editor = ref(null)
const editChatMessage = ref(0)
const chatFileDelete = ref({
  messageID: 0,
  fileID: 0
})
const toolbar = ref([
  [
    {
      header: [false, 1, 2, 3, 4, 5, 6]
    }
  ],
  ["bold", "italic", "underline", "strike"], // toggled buttons
  [
    {
      color: []
    },
    {
      background: []
    }
  ], // dropdown with defaults from theme
  ["link"],
  ["clean"] // remove formatting button
])
const modules = {
  name: 'quilMention',
  module: QuilMention,
  options: {
    allowedChars: /^[A-Za-zА-Яа-я\sÅÄÖåäö]*$/,
    mentionDenotationChars: ["@"],
    source: chatUsers
  }
}

const {getRootProps, getInputProps} = useDropzone({onDrop});

await store.dispatch("fetchDirectory", {
  'ei': 'simple',
  'purchase_plan_methods': 'simple',
  'okato': 'simple',
  'cost_centers': 'simple',
  'fin_sources': 'simple',
  'expenses_types': 'simple',
  'cec': 'simple',
  'etc': 'simple',
  'type_sign_user': 'simple',
})

const itemLoadResult = reactive({
  ok: undefined,
  message: '',
})

async function loadItem(id) {
  await PurchaseRequestService.item(id)
      .then(({data}) => {
        item.value = data
        signers.value = []
        itemLoadResult.ok = true
      }).catch((e) => {
        itemLoadResult.ok = false
        itemLoadResult.message = e.response.data.message
      })
}

async function loadChatMessages(id) {
  await PurchaseRequestService.getChatMessages(id, request.value)
      .then(({data}) => {
        chatMessages.value = data
      }).catch(() => {

      })
}

await loadItem(route.params.id)
await loadChatMessages(route.params.id)

await UserService.list({
  GROUPS_IDS: [10]
})
    .then(({data}) => {
      chiefs.value = data
    }).catch(() => {

    })

await UserService.list({
  GROUPS_IDS: [9, 1, 13, 15]
})
    .then(({data}) => {
      writers.value = data
    }).catch(() => {

    })

await UserService.list({
  GROUPS_IDS: [14]
})
    .then(({data}) => {
      ecps.value = data
    }).catch(() => {

    })

await UserService.list({
  GROUPS_IDS: [12]
})
    .then(({data}) => {
      uozers.value = data
    }).catch(() => {

    })

await UserService.list({})
    .then(({data}) => {
      readers.value = data
    }).catch(() => {

    })

const readersList = computed(() => {
  let r = []
  readers.value.forEach(function (f) {
    if (store.getters.currentUser.ID !== f.ID) {
      r.push({value: f.ID, label: f.FULL_NAME})
    } else {
      r.push({value: f.ID, label: f.FULL_NAME, disabled: true})
    }
  })
  return r
})
const writerList = computed(() => {
  let r = []
  writers.value.forEach(function (f) {
    if (store.getters.currentUser.ID !== f.ID) {
      r.push({value: f.ID, label: f.FULL_NAME})
    } else {
      r.push({value: f.ID, label: f.FULL_NAME, disabled: true})
    }
  })
  return r
})
const createdByList = computed(() => {
  let r = []
  ecps.value.forEach(function (f) {
    r.push({value: f.ID, label: f.FULL_NAME})
  })

  return r
})
const changeStatuses = computed(() => {
  return item.value.AVAILABLE_TRANSITIONS
})
const readersChecked = computed(() => {
  let r = []
  item.value.USER_RIGHTS.READ.forEach(function (f) {
    r.push(readers.value.find(i => i.ID === f).FULL_NAME)
  })
  return r.join(', ')
})
const writersChecked = computed(() => {
  let r = []
  item.value.USER_RIGHTS.EDIT.forEach(function (f) {
    r.push(writers.value.find(i => i.ID === f).FULL_NAME)
  })
  return r.join(', ')
})

async function saveItem(hideToasts) {
  return PurchaseRequestService.update(item.value)
      .then(async ({data}) => {
        item.value = data
        if (!hideToasts) {
          toast.success("Данные обновлены", {
            timeout: 2000
          });
        }
        return data
      }).catch(async ({response}) => {
        if (!hideToasts) {
          toast.error(response.data.message, {
            timeout: 5000
          })
        }

        return response.data
      })
}

async function saveViolated(hideToasts) {
  return PurchaseRequestService.violated(route.params.id, {UF_IS_VIOLATED: item.value.UF_IS_VIOLATED})
      .then(async ({data}) => {
        item.value.UF_IS_VIOLATED = data.UF_IS_VIOLATED
        if (!hideToasts) {
          toast.success("Данные обновлены", {
            timeout: 2000
          });
        }
        return data
      }).catch(async ({response}) => {
        if (!hideToasts) {
          toast.error(response.data.message, {
            timeout: 5000
          })
        }

        return response.data
      })
}

async function saveNoticeNumber(hideToasts) {
  return PurchaseRequestService.noticeNumber(route.params.id,
      {
        UF_NOTICE_NUMBER: item.value.UF_NOTICE_NUMBER,
        UF_NOTICE_DATE: item.value.UF_NOTICE_DATE,
      })
      .then(async ({data}) => {
        item.value.UF_NOTICE_NUMBER = data.UF_NOTICE_NUMBER
        item.value.UF_NOTICE_DATE = data.UF_NOTICE_DATE
        shared.closeModal('#edit-notice-number')
        if (!hideToasts) {
          toast.success("Данные обновлены", {
            timeout: 2000
          });
        }
        return data
      }).catch(async ({response}) => {
        if (!hideToasts) {
          toast.error(response.data.message, {
            timeout: 5000
          })
        }

        return response.data
      })
}

async function showStatusHistory() {
  statusHistory.value = []

  await PurchaseRequestService.statusHistory(route.params.id).then(({data}) => {
    statusHistory.value = data
    shared.showModal('#status-history')
  }).catch(() => {

  })
}

async function showFileHistory(h) {
  fileHistory.value = h
  shared.showModal('#file-history')
}

async function onAddFile(e) {
  let files = e.target.files;
  for (let i = 0; i < files.length; i++) {
    let file = files[i];
    let formData = new FormData();
    formData.append("type", e.target.attributes.getNamedItem("fid").value);
    formData.append("file", file);
    await PurchaseRequestService.addDocument(route.params.id, formData).then(({data}) => {
      item.value.DOCUMENTS_GROUPED_BY_TYPE = data.DOCUMENTS_GROUPED_BY_TYPE
    }).catch(() => {

    })
  }
}

async function onChangeFile(e) {
  let files = e.target.files;
  for (let i = 0; i < files.length; i++) {
    let file = files[i];
    let formData = new FormData();
    formData.append("file", file);
    await PurchaseRequestService.changeDocument(route.params.id, e.target.attributes.getNamedItem("fid").value, formData).then(({data}) => {
      item.value.DOCUMENTS_GROUPED_BY_TYPE = data.DOCUMENTS_GROUPED_BY_TYPE
    }).catch(() => {

    })
  }
}

async function deleteDocument(id) {
  await PurchaseRequestService.deleteDocument(route.params.id, id).then(({data}) => {
    item.value.DOCUMENTS_GROUPED_BY_TYPE = data.DOCUMENTS_GROUPED_BY_TYPE
  }).catch(() => {

  })
}

async function deleteDocumentVersion(id, version) {
  await PurchaseRequestService.deleteDocumentVersion(route.params.id, id, version).then(({data}) => {
    item.value.DOCUMENTS_GROUPED_BY_TYPE = data.DOCUMENTS_GROUPED_BY_TYPE
    fileHistory.value.VERSIONS.forEach((v, i) => {
      if (!v) {
        return
      }
      if (v.ID === version) {
        fileHistory.value.VERSIONS.splice(i)
      }
    })
  }).catch(() => {

  })
}

async function saveStatus(data) {
  let canSave = true
  if (item.value.BP_RIGHTS.UF_IS_EDIT) {
    canSave = false
    await saveItem(true).then(async (response) => {
      if (response.message && data.TYPE === 'forward') {
        toast.error(response.message, {
          timeout: 5000
        })
        return
      }
      canSave = true
    })
  }
  if (canSave) {
    await PurchaseRequestService.statusChange(route.params.id, data)
        .then(async ({data}) => {
          if (data.message) {
            toast.error(data.message, {
              timeout: 5000
            })
            return
          }
          shared.closeModal('#edit-status')
          toast.success("Статус изменен", {
            timeout: 2000
          })
          await router.push({name: "ListPurchaseRequest"});
        }).catch(({response}) => {

          toast.error(response.data.message, {
            timeout: 5000
          })
        })
  }
}

async function changeStatus(item) {
  if (item.UF_IS_SET_RESPONSIBLE_UOZ) {
    editStatus.value = {
      UF_RESPONSIBLE_UOZ_ID: 0,
      COMMENT: "",
      UF_PLANNED_DATE_DOCUMENTATION: "",
      TRANSITION: item.NAME,
      TYPE: item.TYPE,
      UF_TEXT_LABEL_CONTROL: item.UF_TEXT_LABEL_CONTROL
    }
    shared.showModal('#edit-uoz-user')
  } else if (item.UF_IS_SET_DATE_START_UOZ) {
    editStatus.value = {
      UF_DATE_START_UOZ: "",
      COMMENT: "",
      TRANSITION: item.NAME,
      TYPE: item.TYPE,
      UF_TEXT_LABEL_CONTROL: item.UF_TEXT_LABEL_CONTROL
    }
    shared.showModal('#edit-uoz-date')
  } else if (item.UF_IS_SET_COMMENT_CHANGE_STATUS) {
    editStatus.value = {
      COMMENT: "",
      TRANSITION: item.NAME,
      TYPE: item.TYPE,
      UF_TEXT_LABEL_CONTROL: item.UF_TEXT_LABEL_CONTROL
    }
    shared.showModal('#edit-status')
  } else {
    await saveStatus({
      COMMENT: "",
      TRANSITION: item.NAME,
      TYPE: item.TYPE
    })
  }
}

async function showEcpInfo(item) {
  edsInfo.value = item
  shared.showModal('#eds-info')
}

async function showModalSign(tp) {
  showSign.value = true
  edsType.value = tp
}

async function showModalSigners() {
  editSigners.value.TYPE = ''
  shared.showModal('#sign-modal')
}


async function closeModalSign() {
  showSign.value = false
}

async function sendEds(val) {
  await PurchaseRequestService.eds(route.params.id, {
    UF_EDS: val,
    TYPE: edsType.value
  })
      .then(async ({data}) => {
        if (!data.EDS.is_valid) {
          toast.error(data.EDS.message, {
            timeout: 5000
          })
        }
        showSign.value = false
        await loadItem(route.params.id)
      }).catch(({response}) => {
        toast.error(response.data.message, {
          timeout: 5000
        })
      })
}

async function updateSigners() {
  await PurchaseRequestService.usersSigners(route.params.id, {
    IDS: signers.value,
    TYPE: editSigners.value.TYPE,
  })
      .then(async ({data}) => {
        if (data.message) {
          toast.error(data.message, {
            timeout: 5000
          })
          return
        }
        shared.closeModal('#sign-modal')
        await loadItem(route.params.id)
      }).catch(({response}) => {

        toast.error(response.data.message, {
          timeout: 5000
        })
      })
}

async function sendForAppproval() {
  await PurchaseRequestService.SendToApproval(route.params.id)
      .then(async ({data}) => {
        console.log(data);
        await loadItem(route.params.id)
      }).catch(({response}) => {
        toast.error(response.data.message, {
          timeout: 5000
        })
      })
}

function setQuoteSelections() {
  let selection = window.getSelection().toString();
  if (selection.length > 0) {
    const popTag = document.getElementById("popup_tag")
    editorData.value += "<blockquote>" + selection + "</blockquote><br/>"

    window.getSelection().removeAllRanges()
    popTag.style.display = "none"
  }
}

document.addEventListener("mouseup", function () {
  const popTag = document.getElementById("popup_tag");
  if (popTag !== null) {
    popTag.style.display = "none";
  }
  setTimeout(() => {
    const selection = window.getSelection();
    const selectionText = selection.toString().trim();
    if (selectionText.length > 0) {
      console.log(selectionText)
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      popTag.style.display = "block";
      popTag.style.position = "fixed"; // fixed positioning = easy mode
      popTag.style.top = rect.top * 0.85 + "px"; // set coordinates
      popTag.style.left = rect.right + 10 + "px";
    }
  }, 500)
})
document.addEventListener("mousedown", function () {
  const popTag = document.getElementById("popup_tag");
  if (window.getSelection().toString().trim().length > 0) {
    popTag.style.display = "none";
  }
})

async function onAddChatFile(e) {
  let files = e.target.files;
  for (let i = 0; i < files.length; i++) {
    let file = files[i];
    chatFiles.value.push(file)
  }
}

async function deleteAddedFile(i) {
  chatFiles.value.splice(i, 1)
}

async function addChatMessage() {
  let formData = new FormData();
  formData.append("UF_MESSAGE", editorData.value);
  for (let i = 0; i < chatFiles.value.length; i++) {
    formData.append("FILES[]", chatFiles.value[i]);
  }
  if (editChatMessage.value === 0) {
    await PurchaseRequestService.addChatMessage(route.params.id, formData).then(() => {
      editorData.value = "<p></p>"
      chatFiles.value = []
      loadChatMessages(route.params.id)
    }).catch(() => {

    })
  } else {
    await PurchaseRequestService.updateChatMessage(route.params.id, editChatMessage.value, formData).then(() => {
      editorData.value = "<p></p>"
      editChatMessage.value = 0
      chatFiles.value = []
      loadChatMessages(route.params.id)
    }).catch(() => {

    })
  }
}

function chatReply(mid, id, name) {
  editor.value.getQuill().getModule('quilMention')
      .insertItem(
          {id: id, value: name, denotationChar: "@"},
          true
      );
}

function chatEdit(mid) {
  if (mid > 0) {
    editChatMessage.value = mid
    editorData.value = chatMessages.value.data.find((el) => el.ID === mid).UF_MESSAGE
  } else {
    editChatMessage.value = 0
    editorData.value = "<p></p>"
  }
}

async function showDeleteChatFile(id, fileID) {
  chatFileDelete.value = {
    messageID: id,
    fileID: fileID
  }
  shared.showModal("#chat-file-delete")
}

async function deleteChatFile() {
  shared.closeModal("#chat-file-delete")
  await PurchaseRequestService.deleteChatFile(route.params.id, chatFileDelete.value.messageID, chatFileDelete.value.fileID).then(() => {
    loadChatMessages(route.params.id)
  }).catch(() => {

  })
}

function chatUsers(searchTerm, renderList, mentionChar) {
  let values = [];

  if (mentionChar === "@") {
    chatMessages.value.USERS.forEach(function (v) {
      values.push({id: v.ID, value: v.FULL_NAME});
    });
  }

  if (searchTerm.length === 0) {
    renderList(values, searchTerm);
  } else {
    const matches = [];
    for (let i = 0; i < values.length; i++)
      if (
          ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
      ) {
        matches.push(values[i]);
      }
    renderList(matches, searchTerm);
  }
}

const pages = computed(() => {
  if (
      !chatMessages.value.meta.total ||
      chatMessages.value.meta.total <= chatMessages.value.meta.per_page
  ) {
    return [];
  }
  return [
    ...Array(Math.ceil(chatMessages.value.meta.total / chatMessages.value.meta.per_page)).keys()
  ].map(e => e + 1);
})
watch(currentPage, async () => {
  request.value.page = currentPage.value
  await loadChatMessages(route.params.id)
})

function showBody() {
  document.querySelector('.js-title-show').addEventListener('click', function () {
    const container = this.closest('.js-container-show');
    if (container) {
      const body = container.querySelector('.js-body-show');
      const icon = this.querySelector('.stl-chevron');

      if (body) {
        body.classList.toggle('show');
      }

      if (icon) {
        icon.classList.toggle('show');
      }
    }
  });
}

let intervalId = 0;
onMounted(() => {
  intervalId = setInterval(async () => {
    await loadChatMessages(route.params.id)
  }, 5000);
  showBody();
});

onUnmounted(() => {
  clearInterval(intervalId);
});

function plannedDateChange(v) {
  PurchaseRequestService.plannedDateDocumentation(route.params.id, {UF_PLANNED_DATE_DOCUMENTATION: v})
      .then(async ({data}) => {
        item.value.UF_IS_VIOLATED = data.UF_IS_VIOLATED
        toast.success("Данные обновлены", {
          timeout: 2000
        });
        return data
      }).catch(async ({response}) => {
    toast.error(response.data.message, {
      timeout: 5000
    })
    return response.data
  })
}

function onDrop(acceptFiles) {
  docFile.value = acceptFiles[0]
}

async function onAddDocFile(e) {
  let files = e.target.files;
  for (let i = 0; i < files.length; i++) {
    let file = files[i];
    docFile.value = file
  }
}

async function deleteDocFile() {
  docFile.value = {}
  shared.closeModal('#procurement-add-document')
}

async function uploadDocFile() {
  let formData = new FormData();
  formData.append("file", docFile.value);
  await PurchaseRequestService.uploadProcurementDocuments(route.params.id, formData).then(async () => {
    await loadItem(route.params.id)
    await deleteDocFile()
  }).catch(({response}) => {

    toast.error(response.data.message, {
      timeout: 5000
    })
  })
}

async function deleteProcurementDocument(id) {
  await PurchaseRequestService.deleteProcurementDocument(route.params.id, id).then(async () => {
    await loadItem(route.params.id)
  }).catch(({response}) => {

    toast.error(response.data.message, {
      timeout: 5000
    })
  })
}

async function exportApprovalSheet() {
  await PurchaseRequestService.exportApprovalSheet(route.params.id)
      .then(async ({data}) => {
        document.location.href = data.url
      }).catch(({response}) => {
        toast.error(response.data.message, {
          timeout: 5000
        });
      })
}

async function setSigners() {
  signers.value = []
  item.value.USER_SIGNS[editSigners.value.TYPE].forEach(function (f) {
    signers.value.push(f.USER.ID)
  })
}

async function showModalSendToRevision() {
  toRevisionForm.value = {}
  shared.showModal('#sending-for-revision')
}

async function sendToRevision() {
  if (toRevisionForm.value.comment) {
    await PurchaseRequestService.sendToRevisionProcurementDocument(route.params.id, toRevisionForm.value)
        .then(async () => {
          await loadItem(route.params.id)
          shared.closeModal('#sending-for-revision')
        }).catch(({response}) => {
          toast.error(response.data.message, {
            timeout: 5000
          });
        })
  } else {
    toast.error("Требуется указать причину отправки на доработку", {
      timeout: 5000
    });
  }
}

function showECPSet(item) {
  if (item.STATUS.UF_CODE != 'work_of_documentation' && item.STATUS.UF_CODE != 'placement_eis' && item.STATUS.UF_CODE != 'purchase_is_placed') {
    return true;
  } else {
    return false;
  }
}

function allSetParralelUserEcp(item) {
  let isSet = true;
  if (item.USER_SIGNS['parallel'].length == 0) {
    isSet = false;
  } else {
    item.USER_SIGNS['parallel'].forEach(function (f) {
      if (!f.UF_IS_EDS) {
        isSet = false;
      }
    });
  }

  return isSet;
}

function showProcurementBlock(item){
  let isView = true;
  if (item.STATUS.UF_CODE == 'approval_of_documentation' && item.PROCUREMENT_DOCUMENTS.length == 0) {
    isView = false;
  }

  return isView;
}

async function showUpdateUOZUser() {
  if (item.value.UF_RESPONSIBLE_UOZ_ID) {
    editUOZ.value.UF_RESPONSIBLE_UOZ_ID = item.value.UF_RESPONSIBLE_UOZ_ID
  } else {
    editUOZ.value.UF_RESPONSIBLE_UOZ_ID = ""
  }
  shared.showModal('#update-uoz-user')
}

async function updateUpdateUOZUser() {
  await PurchaseRequestService.responsibleUOZ(route.params.id, editUOZ.value)
      .then(async () => {
        await loadItem(route.params.id)
        shared.closeModal('#update-uoz-user')
      }).catch(({response}) => {
        toast.error(response.data.message, {
          timeout: 5000
        });
      })
}
</script>
<template>
  <div class="main-wraper" id="js-main-wraper" style="padding-top: 80px">
    <MainHeader></MainHeader>
    <template v-if="itemLoadResult.ok">
      <section class="content-wrapper" id="js-content-wrapper">
        <div class="container-fluid">
          <section class="page-title-container">
            <div class="page-title-container__col">
              <div class="page-breadcrumbs">
                              <span class="stl-fw-600">
                                   <router-link :to="{name: 'ListPurchaseRequest', query: { _r: route.query._r }}">Заявки на закупки</router-link></span>
                / <span
                  class="page-breadcrumbs__item">Заявка на закупку: {{ item.PURCHASE_PLAN.UF_SUBJECT }}</span>
              </div>
              <div class="page-title-aside df-ac" v-show="false">
                <div class="page-title">Заявка на закупку: {{ item.PURCHASE_PLAN.UF_SUBJECT }}
                </div>
              </div>
            </div>
            <div class="page-title-container__col page-title-container__controll">
            </div>
          </section>
          <!-- page-title-container -->
          <section class="page-container">
            <div class="row card-row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 card-col">
                <div class="box-stl card-holder">
                  <div class="holder-title card-title df-ac df-jsb">Общая информация
                    <span class="holder-title__attribute" v-if="item.UF_NOTICE_NUMBER">
                      Извещение № {{ item.UF_NOTICE_NUMBER }}
                      <template v-if="item.UF_NOTICE_DATE">
                        от {{ moment(item.UF_NOTICE_DATE).format("DD.MM.YYYY")}}
                      </template>
                    </span>
                  </div>
                  <div class="card-holder__body">
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col card-holder-col--name">Предмет закупки <span
                          class="c-c-danger">*</span>
                      </div>
                      <div class="card-holder-col">{{ item.PURCHASE_PLAN.UF_SUBJECT }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col card-holder-col--name">План закупок <span class="c-c-danger">*</span>
                      </div>
                      <div class="card-holder-col">
                        <router-link :to="{name: 'CardPurchase', params: { id: item.PURCHASE_PLAN.ID }}">
                          {{ item.PURCHASE_PLAN.ID }} {{ item.PURCHASE_PLAN.UF_SUBJECT }}
                        </router-link>
                      </div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col card-holder-col--name">Цена (НМЦД - Начальная (максимальная) цена
                        договора), руб. <span
                            class="c-c-danger">*</span></div>
                      <div class="card-holder-col">{{ $filters.numberFormat(item.PURCHASE_PLAN.UF_COST, 2) }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col card-holder-col--name">Количество <span class="c-c-danger">*</span>
                      </div>
                      <div class="card-holder-col">{{ item.PURCHASE_PLAN.UF_QUANTITY }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col">Ед. измерения (код по ОКЕИ - Общероссийский классификатор единиц
                        измерения)
                      </div>
                      <div class="card-holder-col" v-if="item.PURCHASE_PLAN.UF_EI_ID">{{
                          store.getters.simpleItem("ei", item.PURCHASE_PLAN.UF_EI_ID).UF_NAME
                        }}, код {{ store.getters.simpleItem("ei", item.PURCHASE_PLAN.UF_EI_ID).UF_CODE }}
                      </div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col card-holder-col--name">Планируемый срок размещения <span
                          class="c-c-danger">*</span></div>
                      <div class="card-holder-col">{{ item.PURCHASE_PLAN.PLACEMENT_DATE }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb" v-if="item.BP_RIGHTS.UF_IS_VIEW_VIOLATED">
                      <div class="card-holder-col">
                        <input type="checkbox" v-model="item.UF_IS_VIOLATED"
                               :disabled="!item.BP_RIGHTS.UF_IS_EDIT_VIOLATED"
                               @change="saveViolated()"
                        > Планируемый срок размещения нарушен
                      </div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col card-holder-col--name">Срок исполнения договора <span
                          class="c-c-danger">*</span></div>
                      <div class="card-holder-col">{{ item.PURCHASE_PLAN.EXECUTION_DATE }}</div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb">
                      <div class="card-holder-col card-holder-col--name">Способ закупки <span
                          class="c-c-danger">*</span>
                      </div>
                      <div class="card-holder-col">{{
                          store.getters.simpleItem("purchase_plan_methods", item.PURCHASE_PLAN.UF_PM_ID).UF_NAME
                        }}
                      </div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb"
                         v-if="item.BP_RIGHTS.UF_IS_VIEW_PLANNED_DATE_DOCUMENTATION !== undefined && item.BP_RIGHTS.UF_IS_VIEW_PLANNED_DATE_DOCUMENTATION">
                      <div class="card-holder-col">Плановый срок формирования документации</div>
                      <div class="card-holder-col" v-if="!item.BP_RIGHTS.UF_IS_SET_PLANNED_DATE_DOCUMENTATION">
                        <template v-if="item.UF_PLANNED_DATE_DOCUMENTATION">
                          {{ moment(item.UF_PLANNED_DATE_DOCUMENTATION).format("DD.MM.YYYY") }}
                        </template>
                      </div>
                      <div class="card-holder-col stl-data-container" v-else>
                        <VueDatePicker v-model="item.UF_PLANNED_DATE_DOCUMENTATION" format="dd.MM.yyyy"
                                       @update:model-value="plannedDateChange"
                        ></VueDatePicker>
                      </div>
                    </div>
                    <div class="card-holder-row df-ac df-jsb" v-if="item.UF_RESPONSIBLE_UOZ_ID">
                      <div class="card-holder-col card-holder-col--name">Ответственный сотрудник УОЗ
                      </div>
                      <div class="card-holder-col">{{item.RESPONSIBLE_UOZ.FULL_NAME}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box-stl card-holder">
                  <div class="holder-title card-title">Статус <span class="c-c-danger">*</span></div>
                  <div class="card-holder__body">
                    <p>{{ item.STATUS.UF_NAME }} <a @click="showStatusHistory"
                                                    class="c-i-icon c-i-time"></a></p>
                  </div>
                </div>
                <div class="box-stl card-holder" v-if="store.getters.currentUser.ACTIVE_GROUP.CODE!=='consonants'">
                  <div class="holder-title card-title">Документы для загрузки</div>
                  <div class="card-holder__body">
                    <div class="card-holder__document-container" :key="dt.ID"
                         v-for="dt in item.DOCUMENTS_GROUPED_BY_TYPE">
                      <div class="stl-mb-20 stl-fw-600">{{ dt.UF_NAME }} <span v-if="dt.UF_IS_REQ"
                                                                               class="c-c-danger">*</span></div>
                      <div class="card-holder__document-row">
                        <div class="card-holder--document-grid">
                          <div class="stl-mr-20" v-if="item.BP_RIGHTS.UF_IS_EDIT">
                            <label class="label-add-file" v-if="dt.UF_IS_MULTY">
                              <input type="file" class="hidden" :fid="dt.ID" @change="onAddFile">
                              <span class="btn btn-add-white df-ac"><b
                                  class="stl-fs-22 stl-mr-10">+</b> Добавить</span>
                            </label>
                            <label class="label-add-file" v-if="!dt.UF_IS_MULTY && dt.ITEMS.length === 0">
                              <input type="file" class="hidden" :fid="dt.ID" @change="onAddFile">
                              <span class="btn btn-add-white df-ac"><b
                                  class="stl-fs-22 stl-mr-10">+</b> Выбрать файл</span>
                            </label>
                            <label class="label-add-file" v-if="!dt.UF_IS_MULTY && dt.ITEMS.length > 0">
                              <input type="file" class="hidden" :fid="dt.ITEMS[0].ID" @change="onChangeFile">
                              <span class="btn btn-add-white df-ac"><b
                                  class="stl-fs-22 stl-mr-10">+</b> Выбрать файл</span>
                            </label>
                          </div>
                          <div class="card-holder__document-name df-fc c-item-mb-5">
                            <div class="df-ac" :key="doc.ID" v-for="doc in dt.ITEMS">
                              <a :href="doc.URL_DOWNLOAD" class="link-doc link-doc-name stl-mr-10" download=""> <i
                                  class="d-i-icon d-i-pdf"></i>{{ doc.FILE_NAME }}</a>
                              <label class="label-add-file" v-if="item.BP_RIGHTS.UF_IS_EDIT && dt.UF_IS_MULTY">
                                <input type="file" class="hidden" :fid="doc.ID" @change="onChangeFile">
                                <span class="c-i-icon c-i-file-update"></span>
                              </label>
                              <label class="label-add-file">
                                <a @click="showFileHistory(doc)" class="c-i-icon c-i-time"></a>
                              </label>
                              <a v-if="item.BP_RIGHTS.UF_IS_EDIT && dt.UF_IS_MULTY"
                                 @click="deleteDocument(doc.ID)" class="c-i-icon c-i-delete"></a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- card-holder__document-row -->
                    </div>
                    <!-- card-holder__document-container -->
                  </div>
                </div>
                <div class="box-stl card-holder">
                  <div class="card-holder__body">
                    <div class="stl-mb-20">
                      <div class="c-text-grey stl-fs-12 stl-mb-5">Инициатор заявки <span class="c-c-danger">*</span>
                      </div>
                      <span>{{ item.CREATED_BY.FULL_NAME }}</span>
                    </div>
                    <ValidateInput
                        :name="'Руководитель инициатора'"
                        required
                        select
                        :items="chiefs"
                        :item-key="'ID'"
                        :item-name="'FULL_NAME'"
                        v-if="item.BP_RIGHTS.UF_IS_EDIT"
                        v-model="item.UF_CHIEF_ID">
                    </ValidateInput>
                    <div class="stl-mb-20" v-else>
                      <div class="c-text-grey stl-fs-12 stl-mb-5">Руководитель инициатора</div>
                      <div class="df-ac">
                        {{ item.CHIEF.FULL_NAME }}
                      </div>
                    </div>
                    <div class="stl-mb-20">
                      <div class="c-text-grey stl-fs-12 stl-mb-5">Имеет доступ на чтение</div>
                      <div class="df-ac" v-if="item.BP_RIGHTS.UF_IS_EDIT">
                        <Multiselect
                            class="c-input c-select c-filter__input c-multiselect-filter"
                            v-model="item.USER_RIGHTS.READ"
                            mode="tags"
                            :searchable="true"
                            :create-option="true"
                            :options="readersList"
                        />
                      </div>
                      <div class="df-ac" v-else>
                        {{ readersChecked }}
                      </div>
                    </div>
                    <div>
                      <div class="c-text-grey stl-fs-12 stl-mb-5">Имеет доступ на редактирование</div>
                      <div class="df-ac" v-if="item.BP_RIGHTS.UF_IS_EDIT">
                        <Multiselect
                            class="c-input c-select c-filter__input c-multiselect-filter"
                            v-model="item.USER_RIGHTS.EDIT"
                            mode="tags"
                            :searchable="true"
                            :create-option="true"
                            :options="writerList"
                        />
                      </div>
                      <div class="df-ac" v-else>
                        {{ writersChecked }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- col-xs-12 col-sm-12 col-md-12 col-lg-6 card-col -->
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 card-col">
                <div class="box-stl card-holder">
                  <div class="holder-title card-title">Переписка по заявке</div>
                  <div class="card-holder__body">
                    <div class="chat-container">
                      <div class="chat-area">
                        <div class="chat-item" :key="m.ID" v-for="m in chatMessages.data">
                          <div class="chat-item-user df-al">
                            <div class="df-fc">
                              <span class="chat-item-user__name">{{ m.CREATED_BY.FULL_NAME }}</span>
                              <span
                                  class="chat-item-user__data">{{
                                  moment(m.UF_DATE_CREATE).format("DD.MM.YYYY HH:mm")
                                }} </span>
                            </div>
                          </div>
                          <!-- chat-item-user -->
                          <div class="chat-item-text">
                            <div class="chat-item-text-holder chat-text-edit">
                              <span
                                  v-if="m.USER_RIGHTS.IS_CAN_UPDATE" @click="chatEdit(m.ID)"
                                  class="chat-text-edit__btn">
                              </span>
                              <div class="chat-item-text-area" v-html="m.UF_MESSAGE"></div>
                              <div class="chat-item-text__files" v-if="m.FILES.length > 0">
                                <b class="h4-title stl-mr-10">Файлы:</b>
                                <div class="chat-item-text__files-list">
                                  <div class="chat-item-text__files-item df-ac" :key="f.ID" v-for="f in m.FILES">
                                    <a :href="f.URL_DOWNLOAD" class="c-link-blue" download=""><i
                                        class="d-i-icon d-i-xls"></i>{{ f.FILE_NAME }}</a>
                                    <span class="c-i-icon c-i-delete" @click="showDeleteChatFile(m.ID, f.ID)"
                                          v-if="m.USER_RIGHTS.IS_CAN_DELETE_FILE"></span>
                                  </div>
                                  <!-- chat-item-text__files-item -->
                                </div>
                                <!-- chat-item-text__files-list -->
                              </div>
                              <!-- chat-item-text__files -->
                            </div>
                            <a @click="chatReply(m.ID, m.CREATED_BY.ID, m.CREATED_BY.FULL_NAME)"
                               class="c-link-blue chat-link-answer jc-chat-answer">Ответить</a>
                          </div>
                          <!-- chat-item-text -->
                        </div>
                      </div>
                      <!-- chat-area -->
                    </div>
                    <!-- chat-container-->
                    <div class="chat-navigation" v-if="chatMessages.meta.total > chatMessages.meta.per_page">
                      <ChatPagination
                          :pages="pages"
                          v-model="currentPage"
                          :total="chatMessages.meta.total"
                          :perPage="10"
                      ></ChatPagination>
                      <ul class="chat-navigation-list" style="display: none">
                        <li class="chat-navigation-list__item">&gt;&gt;</li>
                      </ul>
                    </div>
                    <!-- chat-navigation -->
                    <div class="user-chat-wrapper">
                      <div class="user-chat-text">
                        <QuillEditor v-model:content="editorData" :modules="modules" style="height: 150px"
                                     content="html"
                                     content-type="html"
                                     theme="snow" :toolbar="toolbar" ref="editor"/>
                      </div>
                      <div class="user-chat-controll">
                        <div v-if="chatFiles.length>0" style="margin-bottom: 10px">
                          <div class="chat-item-text__files-item df-ac" :key="index" v-for="(f, index) in chatFiles">
                            {{ f.name }} <a @click="deleteAddedFile(index)"><span
                              class="c-i-icon c-i-delete"></span></a>
                          </div>
                        </div>
                        <label class="label-add-file">
                          <input type="file" class="hidden" @change="onAddChatFile">
                          <span class="btn btn-add-white df-ac"><b
                              class="stl-fs-22 stl-mr-10">+</b> Добавить документ</span>
                        </label>
                        <br/>
                        <div v-if="!editChatMessage" class="user-chat-controll-butt">
                          <button class="btn btn-blue" @click="addChatMessage">отправить</button>
                        </div>
                        <div v-else class="user-chat-controll-butt">
                          <button class="btn btn-blue" @click="addChatMessage">Редактировать</button>
                          <button class="btn btn-red" @click="chatEdit(0)">Отмена</button>
                        </div>
                      </div>
                      <!-- Модальное окно ответа в чате -->
                      <div class="modal-chat-answer js-modal-chat-answer">
                        <div class="modal-chat-answer__controll">
                          <img src="/img/chat-controll-modal.png" alt="">
                        </div>
                        <div class="modal-chat-answer__textarea">
                          <textarea></textarea>
                        </div>
                      </div>
                      <!-- Модальное окно ответа в чате -->

                      <!-- Модальное окно выбора сотрудника для ответа -->
                      <div class="modal-chat-list-answer" id="js-modal-chat-list-answer">
                        <div class="modal-chat-list-answer__title">
                          <span>Сотрудники</span>
                          <span class="close" id="js-close-chat-list"></span>
                        </div>
                        <div class="modal-chat-list-answer__body">
                          <ul>
                            <li>Иванов Иван Иванович</li>
                            <li>Петров Петр Петрович</li>
                            <li>Сидоров Сидр Сидорович</li>
                          </ul>
                        </div>
                      </div>
                      <!-- Модальное окно выбора сотрудника для ответа -->          </div>
                    <!-- chat-control-wrapper -->
                  </div>
                  <!-- card-holder__body -->
                </div>
                <div class="box-stl card-holder js-container-show"
                     v-if="(item.BP_RIGHTS.UF_IS_PURCHASING_DOCUMENTS_BLOCK_VIEW  ||
                      item.BP_RIGHTS.UF_IS_PURCHASING_DOCUMENTS_BLOCK_EDIT) && showProcurementBlock(item)">
                  <div class="holder-title card-title card-title--flex stl-pb-15">
                    <a class="df-ac js-title-show" style="user-select: none;">
                      <span class="stl-chevron"></span>
                      Закупочная документация
                    </a>
                    <div class="butt-flex-container">
                      <button class="btn btn-border-black" v-if="item.BP_RIGHTS.IS_VIEW_BUTTON_APPROVAL_SHEET"
                              @click="exportApprovalSheet">Лист согласования
                      </button>
                      <button class="btn btn-blue" v-if="item.BP_RIGHTS.UF_IS_PURCHASING_DOCUMENTS_BLOCK_EDIT"
                              @click="shared.showModal('#procurement-add-document')">
                        Загрузить документ
                      </button>
                    </div>
                  </div>
                  <div class="card-holder__body procurement-documents-wrapper js-body-show stl-pt-0">
                    <div class="card-holder-row df-ac df-jsb procurement-documents-row" :key="doc.ID"
                         v-for="doc in item.PROCUREMENT_DOCUMENTS">
                      <div class="df-ac">
                        <span class="d-i-icon d-i-doc-light"></span>
                        <a :href="doc.URL_DOWNLOAD" class="procurement-documents__link"
                           download="">{{ doc.FILE_NAME }}</a>
                      </div>
                      <button class="c-i-delete-grey" v-if="item.BP_RIGHTS.UF_IS_PURCHASING_DOCUMENTS_BLOCK_EDIT"
                              @click="deleteProcurementDocument(doc.ID)"></button>
                    </div>
                  </div>
                  <!-- card-holder__body -->
                </div>
                <div class="box-stl card-holder js-container-show" v-if="item.BP_RIGHTS.UF_IS_SIGNATORIES_BLOCK_VIEW">
                  <div class="holder-title card-title card-title--flex">
                    Подписывают
                    <div class="butt-flex-container">
                      <template v-if="showECPSet(item) && allSetParralelUserEcp(item)">
                        <button class="btn btn-green" @click="showModalSign('consistent')"
                                v-if="
                                (item.UF_SIGN_FOR_APPROVAL_STATUS !== 1 && item.UF_SIGN_FOR_APPROVAL_STATUS !== 2)  &&
                                item.USER_SIGNS.consistent.length > 0 &&
                                !item.USER_SIGNS.consistent[0].UF_IS_EDS &&
                                store.getters.currentUser.ID===item.USER_SIGNS.consistent[0].USER.ID"
                        >Подписать
                        </button>
                        <button class="btn btn-red"
                                @click="showModalSendToRevision"
                                v-if="
                                (item.UF_SIGN_FOR_APPROVAL_STATUS !== 1 && item.UF_SIGN_FOR_APPROVAL_STATUS !== 2)  &&
                                item.USER_SIGNS.consistent.length > 0 &&
                                !item.USER_SIGNS.consistent[0].UF_IS_EDS &&
                                store.getters.currentUser.ID===item.USER_SIGNS.consistent[0].USER.ID">
                          Отправить на доработку
                        </button>
                      </template>
                      <button class="btn btn-blue" @click="showModalSigners" v-if="item.BP_RIGHTS.UF_IS_SIGNATORIES_BLOCK_EDIT &&
                        (item.USER_SIGNS.parallel.length > 0 || item.USER_SIGNS.consistent.length > 0)
                        ">Изменить
                      </button>
                      <button class="btn btn-blue" @click="showModalSigners"
                              v-else-if="item.BP_RIGHTS.UF_IS_SIGNATORIES_BLOCK_EDIT">Добавить
                      </button>
                    </div>
                  </div>
                  <div class="card-holder__body">
                    <div class="sign-container">
                      <b class="sign-title">Параллельное</b>
                      <ol class="sign-list">
                        <li
                            :key="sign.ID"
                            v-for="sign in item.USER_SIGNS.parallel">{{ sign.USER.FULL_NAME }}
                          <button class="btn btn-blue sign-btn-ecp-sm"
                                  v-if="showECPSet(item) && !sign.UF_IS_EDS && store.getters.currentUser.ID===sign.USER.ID"
                                  @click="showModalSign('parallel')">Подписать
                          </button>

                          <span
                               v-if="sign.EDS && sign.STATUS.UF_CODE==='agreed'"
                               @click="showEcpInfo(sign.EDS.data)"
                               class="c-text-green stl-fw-bold"
                               >Подписано</span>
                          <span v-if="sign.EDS && sign.STATUS.UF_CODE==='rejected'"
                                style="color:red;"
                                class="c-text-red stl-fw-bold">Не подписано</span>
                          <span class="sign-ecp-data"
                                v-if="sign.EDS && sign.STATUS.UF_CODE==='agreed'"
                                style="cursor: pointer"
                                @click="showEcpInfo(sign.EDS.data)"
                          >{{
                              moment(sign.UF_DATE_MODIFIED).format("DD.MM.YYYY HH:mm")
                            }}<!--<a
                                @click="showEcpInfo(sign.EDS.data)"
                                class="stl-ml-10 c-i-icon c-i-etsp"></a>-->
                          </span>
                        </li>
                      </ol>
                    </div>
                    <div class="sign-container">
                      <b class="sign-title">Последовательное</b>
                      <ol class="sign-list">
                        <li :key="sign.ID" v-for="sign in item.USER_SIGNS.consistent">{{ sign.USER.FULL_NAME }}
                          <span v-if="sign.EDS && sign.STATUS.UF_CODE==='agreed'"
                                class="c-text-green stl-fw-bold"
                          >Подписано</span>
                          <span v-if="sign.EDS && sign.STATUS.UF_CODE==='rejected'"
                                style="color:red;"
                                class="c-text-red stl-fw-bold"
                          >Не подписано</span>
                          <span class="sign-ecp-data"
                                v-if="sign.EDS && sign.STATUS.UF_CODE==='agreed'"
                                style="cursor: pointer;"
                                @click="showEcpInfo(sign.EDS.data)"
                          >{{
                              moment(sign.UF_DATE_MODIFIED).format("DD.MM.YYYY HH:mm")
                            }}<!--<a
                                @click="showEcpInfo(sign.EDS.data)"
                                class="stl-ml-10 c-i-icon c-i-etsp"></a>-->
                          </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <!-- card-holder__body -->
                </div>
              </div>
              <!-- col-xs-12 col-sm-12 col-md-12 col-lg-6 card-col -->
            </div>
          </section>
          <!-- page-container -->
        </div>
      </section>
      <MainFooter>
        <template v-slot:left-control>
          <div class="footer-left__controll">
            <button class="btn btn-blue" @click="saveItem(false)" v-if="item.BP_RIGHTS.UF_IS_EDIT">Сохранить</button>
            <button class="btn btn-blue" @click="shared.showModal('#edit-notice-number')"
                    v-if="item.BP_RIGHTS.UF_IS_SET_NOTICE_NUMBER">Извещение
            </button>
            <button class="btn btn-blue" @click="showUpdateUOZUser"
                    v-if="item.BP_RIGHTS.UF_IS_CHANGE_RESPONSIBLE_UOZ">Сменить ответственного
            </button>

            <template v-if="item.BP_RIGHTS.UF_IS_CHANGE_STATUS">
              <template v-for="i in changeStatuses">
                <button
                    class="btn btn-green"
                    :key="i.TO_STATUS_ID"
                    v-if="i.TO_STATUS_ID === 7 && item.UF_SIGN_FOR_APPROVAL_STATUS===2"
                    @click="sendForAppproval"
                >Отправить на согласование
                </button>
              </template>
            </template>

            <span v-if="item.BP_RIGHTS.UF_IS_CHANGE_STATUS">
              <button
                  class="btn"
                  v-for="i in changeStatuses"
                  :key="i.NAME"
                  :title="i.STATUS.MESSAGE"
                  :disabled="!i.STATUS.AVAILABLE"
                  :style="{
                  cursor: !i.STATUS.AVAILABLE ? 'help' : undefined
                }"
                  :class="{
                  'btn-green': i.TYPE==='forward',
                  'btn-red': i.TYPE === 'backward'
                }"
                  v-show="i.TO_STATUS_ID != 7"
                  @click="changeStatus(i)"
              >{{ i.UF_TEXT_BUTTON_CHANGE }}</button>
              </span>
          </div>
        </template>
        <template v-slot:default>
          <div class="footer-right__controll">

          </div>
        </template>
      </MainFooter>
      <!-- footer -->
    </template>
    <div v-if="itemLoadResult.ok === false">
      <h2 style="margin-left: 50px; margin-top: 50px;">{{ itemLoadResult.message }}</h2>
    </div>
  </div>

  <div class="modal" id="procurement-add-document" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Закупка № {{ item.ID }}</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#procurement-add-document')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <div class="label-add-file transparent" v-bind="getRootProps()">
            <input v-bind="getInputProps()" :multiple="false" @change="onAddDocFile">
            <span class="btn btn-add-black df-ac df-jc" v-if="!docFile || !docFile.name">Выберите или перетащите сюда файл</span>
            <span class="btn btn-add-black df-ac df-jc" v-else>{{ docFile.name }}</span>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="deleteDocFile">отмена</button>
          <button type="button" class="btn btn-blue" @click="uploadDocFile">загрузить</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="sign-modal" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Подписывают</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#sign-modal')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Тип согласования <span class="c-c-danger">*</span></label>
                <select class="c-input c-select" @change="setSigners()" v-model="editSigners.TYPE">
                  <option value="" disabled>Выберите из списка</option>
                  <option :value="i.UF_CODE" :key="i.UF_CODE"
                          v-for="i in store.state.directory.simple.type_sign_user">{{ i.UF_NAME }}
                  </option>
                </select>
            </div>
            <div class="df-fc modal-grid-row"
                 v-if="editSigners.TYPE !== ''">
              <label for="" class="form-label">Согласующие <span class="c-c-danger">*</span></label>
              <Multiselect
                  placeholder=""
                  class="c-input c-select c-filter__input c-multiselect-filter stl-multiselect-custom" style="height: auto"
                  v-model="signers"
                  :max="store.getters.simpleItemCode('type_sign_user', editSigners.TYPE).UF_IS_ONE_USER_SET === 1?1:100000"
                  mode="tags"
                  :searchable="true"
                  :aria-expanded="true"
                  :options="createdByList"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#sign-modal')">отмена</button>
          <button type="button" class="btn btn-blue" @click="updateSigners()">сохранить</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="purchasing-send" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body modal-body-text">
          <p><b>Вы уверенны, что хотите отправить закупочную документацию на согласование?</b></p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-bg-gray">отмена</button>
          <button type="button" class="btn btn-blue">Отправить</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="sending-for-revision" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Укажите причину отправки на доработку </h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#sending-for-revision')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Причина <span class="c-c-danger">*</span></label>
              <textarea v-model="toRevisionForm.comment" class="c-input c-textarea c-textarea-n-resize"
                        placeholder="Ваш текст"></textarea>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#sending-for-revision')">закрыть
          </button>
          <button type="button" class="btn btn-blue" @click="sendToRevision">на доработку</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="edit-status" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Добавление комментария при возврате на доработку</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#edit-status')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">{{ editStatus.UF_TEXT_LABEL_CONTROL }}<span
                  class="c-c-danger">*</span></label>
              <textarea type="text" class="c-input c-textarea c-textarea-n-resize"
                        v-model="editStatus.COMMENT"></textarea>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#edit-status')">отменить
          </button>
          <button type="button" class="btn btn-blue" @click="saveStatus(editStatus)">
            вернуть
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="edit-notice-number" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Ввод данных об извещении</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#edit-notice-number')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">№ извещения<span
                  class="c-c-danger">*</span></label>
              <input type="text" class="c-input "
                     v-model="item.UF_NOTICE_NUMBER"/>
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Дата извещения<span
                  class="c-c-danger">*</span></label>
              <VueDatePicker v-model="item.UF_NOTICE_DATE" format="dd.MM.yyyy"
                             class="c-filter__item-data-inp c-filter__item-full"></VueDatePicker>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#edit-notice-number')">отменить
          </button>
          <button type="button" class="btn btn-blue" @click="saveNoticeNumber()">
            сохранить
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="status-history" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">История изменений статуса карточки закупки</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#status-history')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <table class="card-table">
            <thead>
            <tr>
              <th>ФИО</th>
              <th>Статус</th>
              <th>Дата начала действия</th>
              <th>Дата окончания действия</th>
            </tr>
            </thead>
            <tbody>
            <tr :key="i.ID" v-for="i in statusHistory">
              <td>{{ i.CREATED_BY.FULL_NAME }}</td>
              <td>{{ i.STATUS.UF_NAME }}
                <span v-if="i.UF_COMMENT"><br/>Комментарий:<br/>{{ i.UF_COMMENT }}</span>
              </td>
              <td>{{ moment(i.UF_ACTIVE_FROM).format("DD.MM.YYYY HH:mm") }}
                <div v-if="i.UF_DATE_START_UOZ">Дата начала действия статуса УОЗ:
                  {{ moment(i.UF_DATE_START_UOZ).format("DD.MM.YYYY") }}
                </div>
              </td>
              <td v-if="i.UF_ACTIVE_TO">{{ moment(i.UF_ACTIVE_TO).format("DD.MM.YYYY HH:mm") }}</td>
              <td v-else></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="file-history" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">История загрузки документа</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#file-history')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <h2>Текущая версия</h2><br/>
          <div v-if="fileHistory.FILE_NAME">
            <span><a :href="fileHistory.URL_DOWNLOAD">{{ fileHistory.FILE_NAME }}</a>
              {{ fileHistory.CREATED_BY.FULL_NAME }}
              {{ fileHistory.UPLOAD_DATE_FORMATTED }}</span>
          </div>
          <div v-if="fileHistory.VERSIONS && fileHistory.VERSIONS.length">
            <br/><br/>
            <h2>Архив</h2><br/>
            <div :key="i.ID" v-for="i in fileHistory.VERSIONS">
            <span><a :href="i.URL_DOWNLOAD">{{ i.FILE_NAME }}</a> {{ i.CREATED_BY.FULL_NAME }}
              {{ i.UPLOAD_DATE_FORMATTED }}
            <a v-if="item.BP_RIGHTS.UF_IS_EDIT"
               @click="deleteDocumentVersion(fileHistory.ID, i.ID)" class="c-i-icon c-i-delete"></a>
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="chat-file-delete" tabindex="-1">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Удалить файл</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#chat-file-delete')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="modal-text">
              <p>Вы действительно хотите удалить файл?</p>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#chat-file-delete')">Нет
          </button>
          <button type="button" class="btn btn-red" @click="deleteChatFile()">Да</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="edit-uoz-user" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Назначение ответственного сотрудника</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#edit-uoz-user')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">{{ editStatus.UF_TEXT_LABEL_CONTROL }}<span
                  class="c-c-danger">*</span></label>
              <select v-model="editStatus.UF_RESPONSIBLE_UOZ_ID" class="c-input c-select">
                <option value="" disabled>Выберите сотрудника</option>
                <option :value="i.ID"
                        :key="i.ID" v-for="i in uozers">{{ i.FULL_NAME }}
                </option>
              </select>
            </div>
            <div class="df-fc modal-grid-row modal-data-container">
              <label for="" class="form-label">Плановый срок формирования документации</label>
              <VueDatePicker v-model="editStatus.UF_PLANNED_DATE_DOCUMENTATION" format="dd.MM.yyyy"></VueDatePicker>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#edit-uoz-user')">отменить
          </button>
          <button type="button" class="btn btn-blue" @click="saveStatus(editStatus)">
            назначить
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="update-uoz-user" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Изменить ответственного сотрудника</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#update-uoz-user')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Ответственный сотрудник<span
                  class="c-c-danger">*</span></label>
              <select v-model="editUOZ.UF_RESPONSIBLE_UOZ_ID" class="c-input c-select">
                <option value="" disabled>Выберите сотрудника</option>
                <option :value="i.ID"
                        :key="i.ID" v-for="i in uozers">{{ i.FULL_NAME }}
                </option>
              </select>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#update-uoz-user')">отменить
          </button>
          <button type="button" class="btn btn-blue" @click="updateUpdateUOZUser()">
            Изменить
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="edit-uoz-date" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Ввод даты начала действия статуса</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#edit-uoz-date')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row modal-data-container">
              <label for="" class="form-label">Дата начала действия статуса</label>
              <VueDatePicker v-model="editStatus.UF_DATE_START_UOZ" format="dd.MM.yyyy"></VueDatePicker>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#edit-uoz-date')">отменить
          </button>
          <button type="button" class="btn btn-blue" @click="saveStatus(editStatus)">
            сохранить
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="eds-info" tabindex="-1">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Сведения о сертификате ЭП</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#eds-info')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <div class="modal-text">
            Сертификат: {{ edsInfo.thumbprint }}
          </div>
          <div class="modal-text">
            Кем выдан: {{ edsInfo.issuer }}
          </div>
          <div class="modal-text">
            Организация: {{ edsInfo.author.company }}
          </div>
          <div class="modal-text">
            Кому выдан: {{ edsInfo.author.full_name }}
          </div>
          <div class="modal-text">
            Действителен: от {{ edsInfo.valid_from }} до {{ edsInfo.valid_to }}
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-blue" @click="shared.closeModal('#eds-info')">ОК</button>
        </div>
      </div>
    </div>
  </div>
  <ECP :sign-title="'Подписать заявку на закупку'"
       :sign-data="item.UF_HASH" :close-modal="closeModalSign"
       :sign-callback="sendEds" v-if="showSign"></ECP>
  <button
      type="button"
      @mousedown="setQuoteSelections"
      class="popup_tag"
      id="popup_tag"
      ref="popupTag"
      style="display: none"
  >
    <img
        src="/img/block-quote.svg"
        alt=""
        style="transform: rotate(180deg); width: 32px;"
    />
  </button>
</template>
