import { createRouter, createWebHistory } from 'vue-router'

import AuthPage from "@/components/AuthPage.vue";
import ListPurchase from "@/components/purchase/ListPurchase.vue";
import HomePage from "@/components/HomePage.vue";
import CardPurchase from "@/components/purchase/CardPurchase.vue";
import Support from "@/components/Support.vue";
import Profile from "@/components/Profile.vue";
import ListPurchaseRequest from "@/components/purchase-request/ListPurchaseRequest.vue";
import CardPurchaseRequest from "@/components/purchase-request/CardPurchaseRequest.vue";
import ListContract from "@/components/contracts/ListContract.vue";
import CardContract from "@/components/contracts/CardContract.vue";
import ListAdministration from "@/components/administration/ListAdministration.vue";
import CardAdministration from "@/components/administration/CardAdministration.vue";

const router = createRouter({
  routes: [
    {
      path: "/",
      name: "HomePage",
      component: HomePage
    },
    {
      path: "/DAIT",
      name: "Auth",
      component: AuthPage
    },
    {
      path: "/purchase",
      name: "ListPurchase",
      component: ListPurchase,
      meta: {
        title: "План закупок"
      }
    },
    {
      path: "/purchase-request",
      name: "ListPurchaseRequest",
      component: ListPurchaseRequest,
      meta: {
        title: "Заявки на закупки"
      }
    },
    {
      path: "/contract",
      name: "ListContract",
      component: ListContract,
      meta: {
        title: "Договоры"
      }
    },
    {
      path: "/contract/:id",
      name: "CardContract",
      component: CardContract,
      meta: {
        title: "Договоры"
      }
    },
    {
      path: "/support",
      name: "Support",
      component: Support,
      meta: {
        title: "Техподдержка"
      }
    },
    {
      path: "/purchase/:id",
      name: "CardPurchase",
      component: CardPurchase,
      meta: {
        title: "План закупок"
      }
    },
    {
      path: "/purchase-request/:id",
      name: "CardPurchaseRequest",
      component: CardPurchaseRequest,
      meta: {
        title: "Заявки на закупки"
      }
    },
    {
      path: "/administration",
      name: "ListAdministration",
      component: ListAdministration,
      meta: {
        title: "Администрирование"
      }
    },
    {
      path: "/administration/:id",
      name: "CardAdministration",
      component: CardAdministration,
      meta: {
        title: "Администрирование"
      }
    },
    {
      path: "/profile",
      name: "Profile",
      component: Profile,
      meta: {
        title: "Профиль пользователя"
      }
    },
  ],
  history: createWebHistory(process.env.BASE_URL)
});
const DEFAULT_TITLE = 'ФРП';
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || DEFAULT_TITLE;
  next();
});
export default router;
