<script setup async>
import HeaderUser from "@/components/partial/HeaderUser.vue";
import {useRouter} from "vue-router";
import {onMounted} from "vue";
import {useStore} from "vuex";

const router = useRouter()
const store = useStore()

function wrapperTopPadding() {
  let headerHeight = document.getElementById("js-header").clientHeight;
  let mainWrapper = document.getElementById("js-main-wraper");
  mainWrapper.style.paddingTop = headerHeight + 10 + "px";
}

window.addEventListener("resize", () => {
  wrapperTopPadding()
});

onMounted(async () => {
  wrapperTopPadding()
})


</script>
<template>
  <header class="header" id="js-header">
    <div class="header-area">
      <div class="header-nav">
        <a href="/" class="header-logo"><img src="/img/header/logo.png" alt="FRP"></a>
        <a href="#" class="menu-mobile" id="js-menu-mobile">
          <span></span>
        </a>
        <nav class="header-menu" id="js-header-menu">
          <ul class="main-menu">
            <li class="main-menu-item" :class="{
                            active: router.currentRoute.value.name === 'ListPurchase' || router.currentRoute.value.name === 'CardPurchase'
                        }">
              <router-link :to="{name: 'ListPurchase'}">План закупок</router-link>
            </li>
            <li v-if="store.getters.currentUser.ACTIVE_GROUP.CODE!=='employee_oripb'" class="main-menu-item" :class="{
                            active: router.currentRoute.value.name === 'ListPurchaseRequest' || router.currentRoute.value.name === 'CardPurchaseRequest'
                        }">
              <router-link :to="{name: 'ListPurchaseRequest'}">Заявки на закупки</router-link>
            </li>
            <li  class="main-menu-item" :class="{
                            active: router.currentRoute.value.name === 'ListContract' || router.currentRoute.value.name === 'CardContract'
                        }">
              <router-link :to="{name: 'ListContract'}">Договоры</router-link>
            </li>
            <li  v-if="store.getters.currentUser.ACTIVE_GROUP.CODE==='employee_uoz' || store.getters.currentUser.ACTIVE_GROUP.CODE==='admin'"
                 class="main-menu-item" :class="{
                            active: router.currentRoute.value.name === 'ListAdministration' || router.currentRoute.value.name === 'CardAdministration'
                        }">
              <router-link :to="{name: 'ListAdministration'}">Администрирование ПЗ</router-link>
            </li>
            <li class="main-menu-item">
              <a href="https://lkfrprf.bitrix24.site/zakupki/" target="_blank">Справка</a>
            </li>
            <li class="main-menu-item" :class="{
                            active: router.currentRoute.value.name === 'Support'
                        }">
              <router-link :to="{name: 'Support'}">Техподдержка</router-link>
            </li>
          </ul>
        </nav>
      </div>
      <!-- header-nav -->

      <HeaderUser></HeaderUser>
    </div>
    <!-- header-area -->
  </header>
</template>